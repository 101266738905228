.ass-count-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 134px;
}
.ass-container {
  display: flex;
  align-items: flex-end;
  position: relative;
}
.ass-timer {
  font-family: "Inria Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 80px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  position: relative;
  top: 53px;
  right: 32px;
  text-align: left;
}
.ass-timer span {
  font-size: 14px;
  position: relative;
  top: -10px;
  text-align: left;
}
.ass-image {
  height: 85vh;
}
.govno {
  position: absolute;
  top: 57px;
  left: 35%;
  height: 37vh;
}
.hair {
  position: absolute;
  bottom: 21%;
  left: calc(45%);
  animation: wobble 5s infinite;
  width: 9%;
}
.spin {
  animation: feces-spin infinite 2s linear;
}
.eyes {
  position: absolute;
  bottom: 33%;
  left: 47%;
  animation: eyes-spin infinite 2s linear;
  height: calc(2% + 10px);
}
@keyframes feces-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes eyes-spin {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(
      -10px,
      2px
    ); /* Двигается по X на -10px и по Y на -2px */
  }
  100% {
    transform: translate(0, 0); /* Возвращается в начальную позицию */
  }
}
@keyframes wobble {
  0%,
  100% {
    transform: skewY(0deg); /* начальное и конечное состояние */
  }
  50% {
    transform: skewY(10deg); /* максимальный наклон в середине анимации */
  }
}
@media (max-width: 1200px) {
  .ass-count-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-left: 0px;
  }
  .ass-image {
    height: 64vh;
    position: relative;
    top: 10px;
  }
  .govno {
    top: 67px;
    left: 36%;
    height: 23vh;
  }
  .hair {
    bottom: 19%;
    left: calc(45%);
    width: 9%;
  }
  .eyes {
    bottom: 31%;
    left: 47%;
    height: calc(2% + 7px);
  }
  .ass-timer {
    font-size: 48px;
    top: -7px;
    right: inherit;
  }
  .ass-timer span {
    font-size: 12px;
    top: inherit;
  }
}
@media (max-height: 670px) {

  .ass-image {
    height: 50vh;
    position: relative;
    top: 10px;
  }
  .eyes {
    bottom: 30%;
    left: 48%;
    height: calc(2% + 3px);
  }
  .govno {
    top: 37px;
    left: 36%;
    height: 20vh;
  }
}

