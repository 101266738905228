.info-container {
  margin-top: 6%;
}
.containerAside {
  height: 87%;
  width: 270px;
  background-color: #1764FE;
  border-radius: 20px;
  flex-direction: column;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.time {
  font-family: "Inria Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 64px;
  position: relative;
  z-index: 12;
}
.time-info {
  font-family: "Inria Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  text-align: end;
  position: relative;
  left: -15px;
  text-transform: uppercase;
}
.logo {
  margin-top: 25px;
  height: 41px;
  width: 74px;
}
.phone {
  margin-top: 11px;
  position: relative;
  z-index: 6;
  height: 50%;
}
.text-name {
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  color: #fff;
}
.text-aside {
  background: linear-gradient(to left, #1764fe, #0041c3);
  width: 241px;
  height: 25%;
  border-radius: 20px;
  align-self: flex-end;
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  color: #fff;
  padding-right: 50px;
  padding-top: 19px;
  margin-top: 7px;
}
.text-aside div {
  position: relative;
  z-index: 5;
  text-align: end;
}
.man {
  position: absolute;
  bottom: 30px;
  z-index: 0;
  height: 20%;
}
@media (max-height: 900px) {
  .man {
    height: 15%;
  }
  .info-container {
    margin-top: 5%;
  }
}
@media (max-height: 800px) {
  .info-container {
    margin-top: 4%;
  }
}

@media (max-width: 1200px) {
  .time {
    font-family: "Inria Sans", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 40px;
  }
  .time-info {
    font-family: "Inria Sans", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 12px;
    text-align: end;
    left: inherit;
    text-transform: uppercase;
  }
}

