.footer {
  background-color: #1764fe;
  width: 100%;
  height: 200px;
  border-radius: 15px 15px 0 0;
  position: relative;
  z-index: 7;
}
.logo-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 25px;
}
.logo-container div {
  font-family: "Noto Sans", sans-serif;
  font-size: 12px;
  color: #fff;
}
.text-container {
  height: 128px;
  display: flex;
  background: linear-gradient(to left, #1764fe, #0041c3);
  padding-left: 25px;
  padding-right: 18px;
  justify-content: space-between;
  align-items: center;
}
.text-container img {
  height: 76px;
  transform: scaleX(-1);
}
.text-container div {
  font-family: "Noto Sans", sans-serif;
  font-size: 12px;
  color: #fff;
  text-align: right;
  width: 240px;
  font-size: 22px;
}
.click {
  position: absolute;
  position: absolute;
  top: 10px;
  height: 41px;
  left: 135px;
  transform: rotate(-45deg);
  animation: grow 2s infinite ease-in-out;
}
@keyframes grow {
  0%, 100% {
    transform: scale(1) rotate(-45deg);
  }
  50% {
    transform: scale(1.2) rotate(-45deg);
  }
}