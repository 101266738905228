.modal {
  background-color: rgba(33, 33, 33, 0.5);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 5;
}
.modal-info {
  background-color: #fff;
  height: 339px;
  width: 531px;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  position: absolute;
  top: 20%;
  left: 25%;
}
.modal-info-text {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal-container {
  width: 245px;
}
.modal-header {
  font-size: 22px;
  margin-top: 53px;
}
.modal-overview1 {
  text-align: left;
  font-size: 14px;
  margin-top: 26px;
}
.modal-overview2 {
  font-size: 14px;
  margin-top: 14px;
}
.modalX {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 20px;
  color: #a4a4a4;
  cursor: pointer;
}
.modal-button {
  background-color: #ff000f;
  width: 92px;
  height: 32px;
  border-radius: 15px;
  color: #fff;
  margin-top: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  position: relative;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .modal-info {
    background-color: #fff;
    height: 189px;
    width: 295px;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    position: absolute;
    top: 32%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .modal-container {
    width: 154px;
  }
  .modal-header {
    font-size: 12px;
    margin-top: 15px;
    text-align: left;
  }
  .modal-overview1 {
    text-align: left;
    font-size: 10px;
    margin-top: 14px;
  }
  .modal-overview2 {
    font-size: 10px;
    margin-top: 7px;
    text-align: left;
  }
  .modalX {
    position: absolute;
    top: 9px;
    right: 11px;
    font-size: 10px;
    color: #a4a4a4;
    cursor: pointer;
  }
  .modal-button {
    background-color: #ff000f;
    width: 75px;
    height: 23px;
    border-radius: 15px;
    color: #fff;
    margin-top: 10px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    position: relative;
    cursor: pointer;
  }
}
