.App {
  text-align: center;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
button,
button:active,
button:focus {
  border: none;
}
.App {
  background: linear-gradient(#f1973f, #fb66e2, #7037c8);
  height: 100vh;
  display: flex;
}
.App-link {
  color: #61dafb;
}
.app_container_time {
  display: flex;
  justify-content: space-between;
  padding-left: 29px;
  padding-right: 29px;
}
@media (max-width: 1200px) {
  .App {
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
  }
}
